import BannerCTA from "@components/BannerCTA/BannerCTA"
import Intro from "@components/Intro/Intro"
import Layout from "@components/Layout/Layout"
import SplitCard from "@components/SplitCard/SplitCard"
import "@styles/global.scss"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { FluidObject } from "gatsby-image"

interface IHeroImage {
  childImageSharp: {
    fixed: FluidObject
  }
}
interface IData {
  backgroundHero: IHeroImage
  hero: IHeroImage
  whatsHappeningImg: any
}

export default () => {
  const PageTitle: string =
    "What’s Happening at the Garage | Hagerty Learning Garage "
  const metaDescription: string =
    "As an employee training and restoration facility that houses over 60 vehicles, the Hagerty Learning Garage is always evolving. We’re dedicated to sharing our knowledge of the automobile."

  const data: IData = useStaticQuery(graphql`
    {
      backgroundHero: file(relativePath: { eq: "heros/locations.jpg" }) {
        childImageSharp {
          fixed(width: 1800, quality: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      hero: file(relativePath: { eq: "heros/locations.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1100, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      training: file(relativePath: { eq: "training.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      restoration: file(relativePath: { eq: "restoration.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout
      metaPageTitle={PageTitle}
      metaDescription={metaDescription}
      darkTheme={true}
    >
      <Intro
        title="In the Learning Garage"
        body="What good is a fleet of classics that you can’t touch? At Hagerty, we’re proud to offer hands-on learning opportunities for our car-loving employees. "
        backgroundHero={data.backgroundHero.childImageSharp.fixed.src}
        hero={data.hero.childImageSharp.fluid}
      />
      <SplitCard
        title="Employee training facility"
        body="As a training facility for our employees, the Hagerty Learning Garage hosts classes, workshops, and experiences to help us immerse ourselves in the hobby. In the summer, we celebrate the driving season with a series of opportunities for our employees to get behind the wheel. We hit a landmark 80% participation rate in our employee driving experiences during the summer of 2019. "
        fluidImg={data.training.childImageSharp.fluid}
      />
      <SplitCard
        title="Restoration projects for employees"
        body="When we’re not driving, you’ll find us under the hood. With over 60 vehicles in and out of the Learning Garage, there’s always an opportunity for employees to get their hands dirty. Sometimes, we even open the Garage up to employees who need the tools and space to tinker on their own vehicles."
        fluidImg={data.restoration.childImageSharp.fluid}
        rightAlignText={true}
      />
      <BannerCTA />
    </Layout>
  )
}
